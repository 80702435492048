import { USER_TYPES } from 'API';
import { Auth, Storage } from 'aws-amplify';
import { ShouldRender} from 'components';
import Modal from 'components/authentication/modal';
import { UserProfileDetailsCard } from 'components/UserProfileDetailsCard';
import { VideoCarousel } from 'components/VideoCarousel';
import {
  useLinkInstagramCreatorAccount,
  useLinkTikTokCreatorAccount,
  useLinkYoutubeCreatorAccount,
} from 'hooks';
import {
  AVATAR_IMAGE_SIZE_LIMIT_IN_BYTES,
  CREATOR_PROFILE_SUCCESS_MESSAGES,
  MOBILE_DEVICE_ASPECT_RATIO,
  PORTFOLIO_VIDEO_SIZE_LIMIT_IN_BYTES,
  TOAST_CONFIG,
} from 'hooks/utils';
import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { validateFullName } from 'state/auth';
import {
  IProfileImageUpload,
  IUpdateCreatorProfile,
  IUpdateCreatorProfileError,
  withProfile,
} from 'state/profileSteps';
import {
  defaultCreatorProfileError,
  defultCreatorProfileState,
  ProfileProps,
} from 'utils';
import { areFloatsSimilar, getVideoDimensionsOf } from 'utils/utils';
import { v4 as uuid } from 'uuid';
import EditBrandProfile from './brandProfile';
import './creatorProfile.css';
import EditUserProfile from './userProfileSecondPart';

const MOBILE_DEVICE_WIDTH = 1281;

const validatePortfolioContentSize = (size) =>
  size < PORTFOLIO_VIDEO_SIZE_LIMIT_IN_BYTES;

const EditProfile: FC<ProfileProps> = ({
  editCreatorProfile,
  updateProfileLoading,
  profileState,
  setProfileState,
}) => {
  const { data } = profileState;
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<IUpdateCreatorProfile>(
    defultCreatorProfileState
  );
  const [formError, setFormError] = useState<IUpdateCreatorProfileError>(
    defaultCreatorProfileError
  );
  const [error, setError] = useState('');
  const [image] = useState<IProfileImageUpload>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [isSocialSignIn, setIsSocialSignIn] = useState<boolean>(false);
  const [tiktokHandler, setTiktokHandler] = useState('');
  const [youtubeHandler, setYoutubeHandler] = useState('');
  const [instagramHandler, setInstagramHandler] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [upLoading, setUpLoading] = useState(false);
  const [, setImgSizeError] = useState(false);
  const [videoPreviewUrls, setVideoPreviewUrls] = useState<string[]>([]);
  const [contentError, setContentError] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  const {
    linkYoutubeCreatorAccount,
    loading: linkYoutubeLoader,
    data: youtubeAccountData,
  } = useLinkYoutubeCreatorAccount();
  const {
    linkTikTokCreatorAccount,
    loading: linkTiktokLoader,
    data: tiktokAccountData,
  } = useLinkTikTokCreatorAccount();
  const {
    linkInstagramCreatorAccount,
    loading: linkInstagramLoader,
    data: instagramAccountData,
  } = useLinkInstagramCreatorAccount();

  const validateProfileForm = () => {
    const family_name = validateFullName(
      formState?.family_name || '',
      'Surname'
    );

    if (family_name) {
      setFormError({ family_name });
      return false;
    }
    return !image.error;
  };

  const onSubmit = useCallback(async () => {
    const { name, phone_number, email ,tiktokHandler,instagramHandler,youtubeHandler} = formState;
    if (!isLoading && validateProfileForm()) {
      setIsLoading(true);
      if (data?.id && image.file) {
        await Storage.put(`${data?.id}/avatar/profile`, image.file, {
          level: 'public',
          acl: 'public-read',
        });
      }

      updateUserAttributes({
        family_name: ' ',
        name,
        'custom:phoneNumber': phone_number,
      });
    }

    editCreatorProfile({
      name,
      phoneNumber: phone_number,
      email,
      tiktokHandler: tiktokHandler,
      youtubeHandler: youtubeHandler,
      instagramHandler: instagramHandler
    });

    onSuccess(CREATOR_PROFILE_SUCCESS_MESSAGES.ProfileUpdated);
  }, [formState]);

  const updateState = (key: string, value: string) => {
    setFormError((prev) => ({ ...prev, [key]: null }));
    setFormState((prev) => ({ ...prev, [key]: value }));
    setProfileState((prev) => ({ ...prev, [key]: value }));
  };


  const updateUserAttributes = useCallback(
    async ({ family_name, name, 'custom:phoneNumber': phone_number }) => {
      const UserInfoData = {
        family_name,
        name,
        'custom:phoneNumber': phone_number,
      };

      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, UserInfoData);
      } catch (err) {
        setIsLoading(false);
        setError(err.message);
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    },
    []
  );

  const getUser = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser();
    const { family_name, name, email, identities } = user.attributes;
    identities && setIsSocialSignIn(true);
    const state = {
      family_name: family_name || '',
      name: name || '',
      email: email || '',
      phone_number: user.attributes['custom:phoneNumber'] || '',
      tiktokHandler:profileState.data?.tiktokHandler,
      instagramHandler:profileState.data?.instagramHandler,
      youtubeHandler:profileState.data?.youtubeHandler
    } as IUpdateCreatorProfile;
    setFormState((prev) => {
      return { ...prev, ...state };
    });
    if (data?.id) {
      try {
        const url = await Storage.get(`${data?.id}/avatar/profile`);
        fetch(url).then((res) => {
          if (res.status === 200) setAvatar(url);
          if (res.status === 404) setAvatar('');
        });
      } catch (err) {
        console.log('Error', err);
      }
    }
  }, []);

  const onChooseAvatar = useCallback(
    async (files: File[]) => {
      setAvatar(null);
      setImgSizeError(false);
      const profileImage = files[0];
      if (!profileImage || !data) {
        return;
      }

      if (profileImage.size > AVATAR_IMAGE_SIZE_LIMIT_IN_BYTES) {
        setImgSizeError(true);
        return;
      }

      const key = `${data?.id}/avatar/profile`;
      await Storage.put(key, profileImage, {
        level: 'public',
        acl: 'public-read',
      });

      const url = await Storage.get(key);
      const avatar = url?.split('?')[0]?.split('.') || [];
      const profileAvatar = avatar
        .slice(0, 2)
        .concat(avatar.slice(3))
        ?.join('.');

      await editCreatorProfile({
        avatar: profileAvatar,
      });

      setProfileState((prev) => ({
        ...prev,
        data: { ...data, avatar: profileAvatar },
      }));
      setAvatar(url);

      onSuccess(CREATOR_PROFILE_SUCCESS_MESSAGES.AvatarUpdated);
    },
    [data, editCreatorProfile, setProfileState]
  );

  const validationContent = (previewVideos, newVideos) => {
    if (previewVideos?.length + newVideos?.length > 6) {
      setContentError('maximum videos count is 6');
      setTimeout(() => {
        setContentError('');
      }, 3000);
      return false;
    }
    return true;
  };

  const updateProgress = (progress: { loaded: number; total: number }) => {
    setPercentage(Math.floor((progress.loaded / progress.total) * 100));
  };

  const putObject = async (
    key: string,
    file: File,
    progressCallback: (progress: { loaded: number; total: number }) => void
  ) => {
    await Storage.put(key, file, {
      progressCallback,
      level: 'public',
      acl: 'public-read',
    });

    return Storage.get(key);
  };

  const getVideoKeyFromSignedUrl = (signedUrl: string): string =>
    signedUrl.split('public/')?.pop()?.split('?')?.shift() || '';

  const updateProfileStateContent = useCallback(
    (profileContent: string[]) => {
      if (data == null) {
        return;
      }

      setProfileState((prev) => ({
        ...prev,
        data: { ...data, profileContent },
      }));
    },
    [data, setProfileState]
  );
  const onReset = () => {
    setIsLoading(false);
    setUpLoading(false);
    setModalMessage('');
    setShowSuccessModal(false);
    setFileCount(0);
  };

  const onSuccess = (modalMessage: string) => {
    setModalMessage(modalMessage);
    setShowSuccessModal(true);
  };

  const validateVideoSizeRatio = async (file: File) => {
    const { width, height } = await getVideoDimensionsOf(file);
    return (
      width &&
      height &&
      areFloatsSimilar(MOBILE_DEVICE_ASPECT_RATIO, width / height)
    );
  };

  const validateVideoSizeAndRatio = async (file: File) => {
    const isValidRatio = await validateVideoSizeRatio(file);
    const isValidSize = validatePortfolioContentSize(file.size);
    return isValidRatio && isValidSize;
  };

  const onChooseVideos = useCallback(
    async (files) => {
      const previewUrls: string[] = [];
      const uploadedContent: string[] = [];
      const isValidVideo = validationContent(videoPreviewUrls, files);
      if (!files?.length) {
        return;
      }

      if (!isValidVideo) {
        toast.error('Please select a valid video');
      }

      setUpLoading(true);
      setTotalFiles(files?.length);

      for (const file of files) {
        if (!file) {
          continue;
        }

        const isValidVideo = await validateVideoSizeAndRatio(file);
        if (!isValidVideo) {
          onReset();
          toast.error(
            'Video should have size ratio of 16/9 and size under 30mb'
          );
          return;
        }

        const userId = data?.id;
        const fileSuffix = process.env.NODE_ENV === 'development' ? '.dev' : '';

        const key = `content/${userId}/${uuid()}${fileSuffix}`;
        const url = await putObject(key, file, updateProgress);

        setPercentage(0);
        setFileCount((prev) => ++prev);
        uploadedContent.push(key);
        previewUrls.push(url);
      }

      setVideoPreviewUrls((prev) => [
        ...new Set([...(prev || []), ...previewUrls]),
      ]);
      const updatedProfileContent = [
        ...new Set([
          ...(videoPreviewUrls || []).map(getVideoKeyFromSignedUrl),
          ...uploadedContent,
        ]),
      ];
      await editCreatorProfile({
        profileContent: updatedProfileContent,
      });
      updateProfileStateContent(updatedProfileContent);
      onReset();
      onSuccess(CREATOR_PROFILE_SUCCESS_MESSAGES.VideosAdded);
    },
    [data?.id, editCreatorProfile, updateProfileStateContent, videoPreviewUrls]
  );

  const onDeleteVideo = useCallback(
    async (url: string) => {
      const key = url.split('public/').pop()?.split('?').shift();
      if (!videoPreviewUrls?.length || !key) {
        return;
      }

      const content = data?.profileContent || [];
      const previewUrls = videoPreviewUrls || [];

      const updatedProfileContent = content.filter((c) => !c.endsWith(key));
      const updatedPreviewUrls = previewUrls?.filter((u) => !u.includes(url));

      await editCreatorProfile({
        profileContent: updatedProfileContent,
      });

      updateProfileStateContent(updatedProfileContent);
      setVideoPreviewUrls(updatedPreviewUrls);
      onSuccess(CREATOR_PROFILE_SUCCESS_MESSAGES.VideoDeleted);
    },
    [videoPreviewUrls]
  );

  const getContentVideos = async () => {
    const content = data?.profileContent || [];
    const previewUrls: string[] = await Promise.all(
      content?.map((c) => Storage.get((c || '').replace('public/', '')))
    );

    setVideoPreviewUrls(previewUrls);
  };

  const linkYoutubeAccount = () => {
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${process.env.REACT_APP_FRONTEND_BASE_URL}account&prompt=consent&response_type=code&client_id=286667806469-o5nc5g444jouvhi1r8s671f104bakqsl.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube&access_type=offline`;
  };

  const linkInstagramAccount = () => {
    window.location.href = `https://api.instagram.com/oauth/authorize?client_id=305635815781564&state=instagram&redirect_uri=${process.env.REACT_APP_FRONTEND_BASE_URL}account&scope=user_profile&response_type=code`;
  };

  const linkTikTokAccount = () => {
    window.location.href = `https://www.tiktok.com/v2/auth/authorize?client_key=awnfemvqci11wj4b&scope=user.info.basic&response_type=code&state=tiktok&redirect_uri=${process.env.REACT_APP_FRONTEND_BASE_URL}account`;
  };

  useLayoutEffect(() => {
    getContentVideos();
  }, [data?.profileContent]);

  useEffect(() => {
    getUser();
    updateHandlers();

    if (!data?.id) {
      return;
    }

    if (localStorage.getItem('authYoutubeCode') && !youtubeAccountData) {
      const authCode = localStorage.getItem('authYoutubeCode');
      linkYoutubeCreatorAccount({
        variables: { authCode, userProfileId: data?.id },
      });
      localStorage.removeItem('authYoutubeCode');
    } else if (localStorage.getItem('authTikTokCode') && !tiktokAccountData) {
      const authCode = localStorage.getItem('authTikTokCode');
      linkTikTokCreatorAccount({
        variables: { authCode, userProfileId: data?.id },
      });

      localStorage.removeItem('authTikTokCode');
    } else if (
      localStorage.getItem('authInstagramCode') &&
      !instagramAccountData
    ) {
      const authCode = localStorage.getItem('authInstagramCode');
      linkInstagramCreatorAccount({
        variables: { authCode, userProfileId: data?.id },
      });
      localStorage.removeItem('authInstagramCode');
    }

    setTiktokHandler(data?.tiktokHandler ? data?.tiktokHandler : '');
    if (data?.tiktokHandler && localStorage.getItem('tiktok'))
      localStorage.removeItem('tiktok');
    setYoutubeHandler(data?.youtubeHandler ? data?.youtubeHandler : '');
    if (data?.youtubeHandler && localStorage.getItem('youtube'))
      localStorage.removeItem('youtube');
    setInstagramHandler(data?.instagramHandler ? data?.instagramHandler : '');
    if (data?.instagramHandler && localStorage.getItem('instagram'))
      localStorage.removeItem('instagram');
  }, []);

  useEffect(() => {
    if (tiktokAccountData) {
      setTiktokHandler(tiktokAccountData);
      localStorage.setItem('tiktok', tiktokAccountData);
    }

    if (instagramAccountData) {
      setInstagramHandler(instagramAccountData);
      localStorage.setItem('instagram', instagramAccountData);
    }

    if (youtubeAccountData) {
      const { url } = youtubeAccountData;
      setYoutubeHandler(url);
      localStorage.setItem('youtube', url);
    }
  }, [tiktokAccountData, instagramAccountData, youtubeAccountData]);

  const updateHandlers = () => {
    if (
      !youtubeAccountData &&
      !data?.youtubeHandler &&
      localStorage.getItem('youtube')
    ) {
      setYoutubeHandler(localStorage.getItem('youtube') as string);
    }
    if (
      !tiktokAccountData &&
      !data?.tiktokHandler &&
      localStorage.getItem('tiktok')
    ) {
      setTiktokHandler(localStorage.getItem('tiktok') as string);
    }
    if (
      !instagramAccountData &&
      !data?.instagramHandler &&
      localStorage.getItem('instagram')
    ) {
      setInstagramHandler(localStorage.getItem('instagram') as string);
    }
  };

  const onCopy = async (event) => {
    if (!document.hasFocus() || !event) {
      return;
    }

    await navigator.clipboard.writeText(event.target.value);
    toast.success(
      CREATOR_PROFILE_SUCCESS_MESSAGES.CopiedSuccessfully,
      TOAST_CONFIG
    );
  };

  return (
    <>
      <div className="grid xl:grid-cols-2 grid-cols-1 gap-[30px] w-full creator-dashboard p-0 mb-[30px]">
        <div>
          <UserProfileDetailsCard
            avatar={avatar}
            profileState={profileState}
            formState={formState}
            isSocialSignIn={isSocialSignIn}
            modalMessage={modalMessage}
            isLoading={false}
            isModalOpen={showSuccessModal}
            error={error}
            formError={formError}
            onChooseAvatar={onChooseAvatar}
            updateState={updateState}
            onCopy={onCopy}
            onSubmit={onSubmit}
            onCloseModal={onReset}
          />

          {data?.userType === USER_TYPES.BRAND_USER && (
            <div
              className={`border border-[#F5F1E8] bg-white rounded-[16px] min-h-[303px]`}
            >
              <div className="brand-dashboard__profile-title mb-[25px] d-flex justify-between items-center">
                <div className="text-[#0E0D0D] uppercase mt-[23px] sm:ml-[28px] ml-[15px] head-text text-[14px] flex items-center font-[700]">
                  <h6>Add / link accounts</h6>
                </div>
              </div>
              <div className="mt-[10px]">
                <div className="sm:mx-[28px] mx-[15px]">
                  <EditBrandProfile />


                  <ShouldRender if={error}>
                    <p className="error-text">{error}</p>
                  </ShouldRender>
                </div>
              </div>
            </div>
          )}
        </div>

        <VideoCarousel
          isLoading={upLoading}
          isProfileLoading={updateProfileLoading}
          videoUrls={videoPreviewUrls}
          onDelete={onDeleteVideo}
          onChoose={onChooseVideos}
          isAutoPlay={window.innerWidth < MOBILE_DEVICE_WIDTH}
          fileCount={fileCount}
          totalFiles={totalFiles}
          percentage={percentage}
          error={contentError}
        />
      </div>

      <EditUserProfile
        linkingData={{ tiktokHandler, youtubeHandler, instagramHandler }}
      />

      <Modal
        isOpen={showSuccessModal}
        handleClose={onReset}
        content={modalMessage}
        type="brand"
      />
    </>
  );
};

export default withProfile(EditProfile);
